.employee-menu {
    display: flex;
    align-items: center;

    &__item {
        position: relative;

        &:hover {
            .employee-menu__submenu {
                display: block;
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
            }
        }
    }

    &__btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 55px;
        border: none;
        background-color: #fff;
        border-radius: 5px;
        transition: background-color 0.2s ease-in-out;

        &:before {
            content: '';
            height: 15px;
            width: 100%;
            background-color: transparent;
            position: absolute;
            bottom: -15px;
            left: 0;
        }

        svg {
            height: 32px;
        }

        &:hover {
            background-color: #ededed;
        }

        & + .employee-menu__btn {
            margin-left: 8px;
        }
    }

    &__submenu {
        display: none;
        position: absolute;
        top: calc(100% + 15px);
        left: 0;
        min-width: 182px;
        max-width: 250px;
        border-radius: 5px;
        opacity: 0;
        z-index: 3;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
        transition: opacity 0.2s ease-in-out;

        &:before {
            content: '';
            position: absolute;
            top: -20px;
            left: 20px;
            border: 10px solid transparent;
            border-bottom: 10px solid #fff;
            z-index: 2;
        }

        &:after {
            content: '';
            position: absolute;
            top: -24px;
            left: 18px;
            border: 12px solid transparent;
            border-bottom: 12px solid rgba(237, 237, 237, 0.5);
            z-index: 1;
        }

        li {
            &:first-child a {
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
            }
            &:last-child a {
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                border-bottom: none;
            }

            a {
                display: block;
                height: 100%;
                width: 100%;
                padding: 8px 20px;
                background-color: #fff;
                border-bottom: 1px solid #e7e7e7 !important;
                transition: background-color 0.2s ease-in-out;
                overflow: hidden;

                span {
                    display: inline-block;
                    color: $primary;
                    border-bottom: 1px solid transparent;
                    transition: color 0.2s ease-in-out,
                                border-bottom-color 0.2s ease-in-out;
                }

                .fa {
                    color: $primary;
                    transition: color 0.2s ease-in-out;
                }

                &:hover {
                    background-color: #ededed;
                    span {
                        color: $blue;
                        border-bottom-color: $blue;
                    }
                    .fa {
                        color: $blue;
                    }
                }
            }
        }
    }
}
