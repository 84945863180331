.section-products {
    padding-bottom: 0 !important;

    @include desktop {
        padding-top: 10px;
    }

    .section-title {
        margin-bottom: 30px;
        text-align: left;
    }

    .section-content {
        margin-bottom: 30px;
    }

    &-indent {
        padding-bottom: 0;

        .section-content {
            margin-bottom: 0;
        }
    }
}
