.header {
    flex-grow: 0;
    background-color: #fff;
    transition: box-shadow .15s linear;
    box-shadow: 0 0 3px #ccc;

    &-inner {
        height: 95px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
    }
}
