.lang {
    &-wrapper {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
            max-width: 77px;
            margin-left: auto;
            padding: 0;
        }
    }

    &-menu {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            font-weight: 400;

            svg+span {
                margin-left: 15px;
            }

            &.parent {
                position: relative;

                .lang {
                    color: $link;
                    @include underline-blue;
                    margin-right: 5px;
                }

                svg:first-child {
                    display: none;
                }
            }
        }

        a,
        button {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $primary;
            text-transform: uppercase;
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;
        }

        .lang {
            position: relative;
            display: block;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.1;
            transition: color 0.2s linear;
        }

        .submenu {
            display: none;
            width: 105px;
            position: absolute;
            right: 0;
            z-index: 1;
            padding-top: 20px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                box-shadow: 0px 4px 10px 0px rgba(13, 13, 13, 0.3);
                background-color: #ffffff;
            }

            li {
                transition: background-color .15s ease-in-out;

                &:hover {
                    background-color: #d4e8f0;

                    .lang {
                        color: $primary;
                        border-bottom: none;
                    }
                }
            }

            a {
                position: relative;
                width: 92px;
                display: flex;
                padding: 12px 0 12px 12px;
                line-height: 1;

                svg:first-child {
                    display: block;
                }

                .lang {
                    color: $primary;
                    border-bottom: none;
                }
            }
        }
    }
}
