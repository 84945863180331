.slide-main {
    display: flex;
    width: 100%;
    height: 225px;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;

    @include desktop {
        margin-bottom: 50px;
        height: 354px !important;
    }

    @include tablet {
        min-height: 360px;
        max-height: 360px;
    }

    @include desktop {
        min-height: 360px;
        max-height: 360px;
    }

    @include phone {
        min-height: 200px;
        margin-bottom: 26px;
        background: linear-gradient(90deg, rgba(33,69,137,1) 0%, rgba(36,158,207,1) 50%, rgba(33,69,137,1) 100%);
    }

    img {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        z-index: -1;

        @include phone {
            display: none;
        }

        @include tablet {
            object-fit: cover;
            object-position: 70% center;
        }

        @include desktop {
            height: 100%;
        }
    }

    &:first-child {
        position: relative;
        z-index: 2;
    }

    &:not(:first-child) {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
    }

    .slick-slide {
        transition-delay: 1s !important;
    }
}
