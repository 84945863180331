.section-search {
    padding-bottom: 35px;

    .section-title {
        margin-top: 33px;
        margin-bottom: 19px;
        font-size: 26px;
        line-height: 30px;
        text-align: left;

        span {
            color: #4d93ad;
            font-weight: 700;
        }
    }

    .section-content {
        margin-bottom: 30px;
    }

    .search-block {
        border-top: 1px solid #e8e8ec;
        margin-bottom: 0;

        &:last-child {
            border-bottom: 1px solid #e8e8ec;
        }

        &__content {
            padding: 24px 0 22px;
        }
    }
}
