.hamburger {
    outline: none !important;

    &-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-shrink: 0;
        width: 80px;
        height: 100%;
        transform: translateX(-20px);
        padding-left: 35px;
        padding-right: 0;
        border: 0;
        outline: none;

        &.is-active {
            background-color: #70b3cc;

            @include tablet {
                background-color: #70b3cc;
            }

            .hamburger.is-active .hamburger-inner,
            .hamburger.is-active .hamburger-inner:before,
            .hamburger.is-active .hamburger-inner:after {
                background-color: #ffffff;
            }

            .hamburger.is-active .hamburger-inner:before {
                top: -5px;
                right: 2px;
            }

            .hamburger.is-active .hamburger-inner:after {
                bottom: -5px;
                right: 2px;
            }
        }

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    &-inner {
        margin-top: 0;
    }
}
