.product {
    position: relative;
    display: flex;
    padding: 20px 15px;
    border-bottom: 1px solid #e8e8ec;
    color: $primary;
    line-height: 1.3;
    background: $white;
    transition: all 0.2s linear,
        color 0.2s linear;

    @include desktop {
        justify-content: center;
        height: 325px;
    }

    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, #4773b5 0%, #7fbfd7 100%);
        opacity: 0;
        transition: opacity 0.2s linear;
        z-index: 1;
    }

    &:hover {
        text-decoration: none;
        color: $white;
        background-position: 0 0;

        &:after {
            opacity: 1;
        }

        .product-icon-before {
            opacity: 0;
        }

        .product-icon-after {
            opacity: 1;
        }
    }

    &-icon {
        position: relative;
        display: block;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        // margin-right: 15px;
        margin-top: 10px;
        z-index: 3;

        @include phone {
            margin-right: 15px;
        }

        .product-icon-before,
        .product-icon-after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            width: 100%;
            height: 100%;
            transition: opacity 0.2s linear;
        }

        .product-icon-before {
            opacity: 1;
        }

        .product-icon-after {
            opacity: 0;
        }
    }

    &-box {
        position: relative;
        z-index: 3;
    }

    &-name {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 10px;
    }

    &-text {
        font-size: 0.875rem;
        padding-right: 5px;
    }

    &-more {
        position: absolute;
        bottom: 30px;
        right: 15px;
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: rgba($color: #fff, $alpha: 0.9);
        align-self: flex-end;
        z-index: 3;

        &:before,
        &:after {
            content: '';
            position: absolute;
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: rgba($color: #fff, $alpha: 0.9);
        }

        &:before {
            top: 10px;
        }

        &:after {
            bottom: 10px;
        }
    }

    &-content {
        &__block {
            p {
                margin-bottom: 8px;
                font-size: 16px !important;
                line-height: 24px !important;
            }
        }
    }
}
