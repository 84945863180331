.cards {
    &__list {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;

        &--stub {
            display: none;
        }

        @include media-breakpoint-up(sm) {
            flex-basis: 50%;
            max-width: 50%;
            padding-right: 15px;
            padding-top: 15px;
        }

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
            flex-basis: 33.33%;
            max-width: 33.33%;
            padding-top: 0;

            &--row {
                flex-direction: row;
                flex-basis: 50%;
                max-width: 50%;
            }

            &--stub {
                display: flex;
                flex-direction: row;
                align-items: center;

                > div:first-child {
                    position: relative;
                    font-weight: 700;
                    flex: 0 0 55%;
                    max-width: 55%;
                    font-size: 1.125rem;
                    line-height: 1.35;
                    margin-bottom: 35px;
                    margin-right: 25px;
                }

                > div:last-child {
                    position: relative;
                    flex: 0 0 130px;
                    max-width: 130px;
                    height: 100%;

                    img {
                        position: absolute;
                        top: -20px;
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    &__image {
        display: inline-block;
        flex: 0 0 30%;
        max-height: 90px;
        max-width: 130px;
        margin-right: 15px;

        @include media-breakpoint-up(md) {
            height: 75px;
            max-height: none;
            flex: 100%;
            max-width: none;
            margin-right: 0;
            margin-bottom: 15px;

            &--row {
                max-width: 180px;
                max-height: 75px;
                margin-right: 30px;
                margin-bottom: 0;

                img {
                    max-height: 75px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            height: 95px;
            max-height: 95px;
            margin-bottom: 25px;

            &--row {
                max-height: 85px;
                margin-bottom: 0;

                img {
                    max-height: 85px;
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            max-height: 90px;
            object-fit: contain;
        }
    }

    &__name{
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            font-size: 1.125rem;
        }
    }

    &__content{
        .text{
            margin-top: 10px;
        }
    }

    @include media-breakpoint-up(lg) {
        &__item--stub {
            padding: 0 25px;
        }
    }
}
