.slide-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    padding: 20px 40px;
    max-width: 276px;
    height: 54px;
    font-size: 18px;
    color: #fff;
    border-radius: 27px;
    background-color: #5e9ba3;
    transition: none !important;

    @include phone {
        padding: 12px 40px;
        height: 36px;
        max-width: 100%;
        width: 100%;
        font-size: 14px;
        background-color: #70b3cc;
    }

    &:hover {
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff;
    }
}
