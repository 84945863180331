.section-cloud {
    @include desktop {
        padding-bottom: 70px !important;
    }

    &__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        @include phone {
            margin-bottom: 35px;
            flex-direction: column-reverse;
            gap: 35px;
        }

        @include tablet {
            flex-direction: column-reverse;
        }

        @media (min-width: 960px) and (max-width: 1200px) {
            flex-direction: column-reverse;
        }

        p {
            font-size: 16px;
            line-height: 24px;

            @include desktop {
                flex: 0 0 600px;
            }

            @media (min-width: 960px) and (max-width: 1200px) {
                flex: none;
            }
        }

        img {
            @include desktop {
                width: 470px;
            }

            @include tablet {
                max-width: 400px;
            }

            @include phone {
                max-width: 480px;
            }
        }

        &--reverse {
            flex-direction: row-reverse;

            @include phone {
                flex-direction: column-reverse;
            }

            @include tablet {
                flex-direction: column-reverse;
            }

            @media (min-width: 960px) and (max-width: 1200px) {
                flex-direction: column-reverse;
            }
        }
    }
}
