.top-menu {
    display: flex;

    @include media-breakpoint-up(lg) {
        font-size: 1rem;
    }

    &-wrapper {
        display: none;

        @include media-breakpoint-up(xl) {
            @include flex-center;
            padding-right: 0;
            padding-left: 50px;
            justify-content: flex-start;
        }
    }

    &__separator {
        @include tablet {
            width: 165px;
        }

        @media (min-width: 724px) and (max-width: 1024px) {
            width: 165px;
        }

        @media (min-width: 1024px) {
            width: 200px;
        }
    }

    li {
        position: relative;
        text-transform: uppercase;
        margin-right: 20px;
        min-width: 110px;

        &:hover,
        &.active {
            >a {
                color: #4d93ad;
                text-decoration: none;
            }
        }

        &.parent {
            margin-right: 0;
            padding-right: 20px;
            transition: color 0.3s linear;

            .control {
                display: flex;
                align-items: center;
                gap: 14px;
                color: #4d93ad;
                font-weight: 600;
                cursor: pointer;

                span {
                    border-bottom: 1px solid transparent;
                }

                &:hover {
                    span {
                        border-bottom: 1px solid #4d93ad;
                    }
                }

                svg path {
                    stroke:#4d93ad;
                }
            }
        }

        a {
            color: #4d93ad;
            font-weight: 600;
            border-bottom: 1px solid transparent;

            &:hover {
                border-bottom: 1px solid #4d93ad;
            }
        }

        .submenu {
            display: none;
            width: 280px;
            position: absolute;
            left: 0;
            z-index: 1;
            padding-top: 20px;

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                box-shadow: 0px 4px 10px 0px rgba(13, 13, 13, 0.3);
                background-color: #ffffff;
            }

            li {
                margin-right: 0;
                text-transform: none;
                transition: background-color .15s ease-in-out;

                &:hover {
                    background-color: #d4e8f0;
                    border-bottom: none;

                    a {
                        color: $primary;
                        border-bottom: none;
                    }
                }
            }

            a {
                position: relative;
                display: block;
                padding: 9px 10px 9px 14px;
                line-height: 1.4;
                font-weight: 400;
                color: #424952;
                border-bottom: none;
            }
        }
    }
}
