.section-wysiwyg {
    @include phone {
        padding-top: 3rem;
    }

    p {
        font-size: 0.875rem;
        line-height: 1.3;
        margin-bottom: 15px;
    }

    ul {
        line-height: 1.3;
        list-style-type: none;
        margin-bottom: 15px;

        li {
            position: relative;
            font-size: 0.875rem;
            letter-spacing: -0.25px;
            padding-left: 20px;
            margin-bottom: 3px;

            &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 0;
                display: block;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                @include gradient;
            }
        }
    }

    @include media-breakpoint-up(md) {
        p {
            font-size: 1rem;
            line-height: 1.5;
        }

        ul {
            margin-bottom: 20px;
            line-height: 1.5;

            li {
                font-size: 1rem;
                padding-left: 35px;
                margin-bottom: 0;
            }
        }
    }
}
