.section {
    @include media-breakpoint-up(sm) {
        padding-bottom: 35px;
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 45px;
    }

    &-title {
        margin-bottom: 20px;

        @include media-breakpoint-up(sm) {
            text-align: center;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 40px;
        }
    }

    &-content {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            margin-bottom: 35px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }

        @include tablet {
            margin-bottom: 60px;
        }

        &.section-content-not-mb {
            margin-bottom: 0;
        }
    }

    &-action {
        .btn {
            width: 100%;
            max-width: 340px;

            @include media-breakpoint-up(sm) {
                width: auto;
            }
        }

        &--group {
            .btn {
                padding-left: 10px;
                padding-right: 10px;
                margin: 0 5px 10px;

                @include media-breakpoint-up(sm) {
                    padding-left: 32px;
                    padding-right: 32px;
                }
            }
        }
    }

    &-clients {
        @include phone {
            padding-top: 0;
        }
    
        @include desktop {
            padding-top: 12px;
            padding-bottom: 0;

            .h2 {
                font-size: 32px;
            }

            .section-title {
                margin-bottom: 22px;
            }

            .section-content {
                margin-bottom: 40px;
            }
        }
    }
}
