.subtitle-slide {
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 724px) {
        align-items: flex-end;
    }

    &.active {
        opacity: 1;
    }

    &.animated {
        animation-duration: 1.5s;
        animation-delay: 0.3s;

        &.active {
            animation-delay: 0s;
        }
    }
}
