.btn {
    border-width: $border-width;
    border-radius: $border-radius;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 6px 17px;

    @include media-breakpoint-up(sm) {
        font-size: 1rem;
        padding: 9px 40px;
    }

    @include media-breakpoint-up(md) {
        font-size: 1.125rem;
    }

    @include media-breakpoint-up(lg) {
        padding: 10px 48px;
    }
}

.btn-outline-primary {
    @include button-outline-variant(transparent, $color-hover: color-yiq(#4d93ad), $active-background: transparent, $active-border: #70b3cc);
    color: #fff;
    background-color: #70b3cc;

    &:hover,
    &.hover {
        color: #4d93ad;
        background-color: transparent;
    }

    &:hover:focus,
    &.hover:focus {
        color: #4d93ad;
        background-color: transparent;

        &.transparent {
            background-color: transparent;
        }
    }

    &.focus,
    &:focus {
        color: #4d93ad;
        background-color: transparent;
        box-shadow: $btn-focus-box-shadow;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        box-shadow: $btn-focus-box-shadow;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        box-shadow: $btn-focus-box-shadow;
    }

    &.disabled,
    &:disabled {
        background-color: transparent;
        opacity: .5;
    }
}

.btn-outline-white {
    @include button-outline-variant($white, $color-hover: #70b3cc, $active-background: transparent, $active-border: #70b3cc);

    &:hover,
    &.hover {
        color: #70b3cc;
        background-color: #fff;
        border-color: #70b3cc;
    }

    &:hover:focus,
    &.hover:focus {
        color: #70b3cc;
        background-color: #fff;

        &.transparent {
            background-color: transparent;
        }
    }

    &.focus,
    &:focus {
        color: #70b3cc;
        background-color: rgba($white, 0.7);
        box-shadow: $btn-focus-box-shadow;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        box-shadow: $btn-focus-box-shadow;
    }

    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
        box-shadow: $btn-focus-box-shadow;
    }

    &.disabled,
    &:disabled {
        background-color: transparent;
        opacity: .5;
    }
}
