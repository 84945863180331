.years {
    position: relative;
    display: inline;
    font-family: 'Akrobat-Black';
    font-size: 3.125rem;
    line-height: 1;
    letter-spacing: -0.1rem;
    @include gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-left: 20px;

    &:before {
        content: '';
        display: block;
        width: 6px;
        height: 42px;
        position: absolute;
        left: 0;
        top: 10px;
        background-color: #dfdfe5;
    }

    &-text {
        font-size: 1.25rem;
        font-weight: 300;

        @include media-breakpoint-up(sm) {
            position: relative;
            display: inline-block;
            max-width: 260px;
            font-size: 2rem;
            line-height: 1.2;
            font-weight: 300;
            padding-left: 25px;
            margin-bottom: 25px;

            &:before {
                content: '';
                display: block;
                width: 6px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 3px;
                background-color: #dfdfe5;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        flex: 0 0 282px;
        font-size: 18.75rem;
        line-height: 0.8;
        padding-left: 0;
        letter-spacing: 0;
        margin-right: 25px;

        .gradient-num {
            @include gradient;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            opacity: 0;
            letter-spacing: 20px;
            margin-left: 30px;
        }

        &:before {
            display: none;
        }
    }
}
