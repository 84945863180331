.section-treasury {
    .section-title {
        text-align: left;
    }

    @include media-breakpoint-up(sm) {
        padding-bottom: 20px;

        &__content {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .section-title {
            margin-bottom: 25px;
        }
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 35px;
    }

    &__text {
        p {
            font-size: 16px;
        }
    }

    &--pb0 {
        padding-bottom: 0 !important;
    }

    @include desktop {
        padding-bottom: 40px !important;

        .h2 {
            margin-bottom: 35px;
            font-size: 32px;
        }

        p {
            margin-bottom: 1rem;
        }

        .section-content {
            margin-bottom: 0;
        }
    }

    @include tablet {
        .section-content {
            margin-bottom: 0;
        }
    }

    @include phone {
        margin-top: 35px;

        p {
            margin-bottom: 1rem;
        }
    }
}
