.social {
    float: left;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #9da5b2;
    background-size: auto 14px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.2s linear;

    &:not(:last-child) {
        margin-right: 9px;
    }

    &:hover {
        background-color: #ffffff;
    }

    &--linkedin {
        background-image: url('../../../images/base/linkedin.svg');
    }

    &--facebook {
        background-image: url('../../../images/base/facebook.svg');
    }

    &--telegram {
        background-image: url('../../../images/base/telegram.svg');
    }

    &--youtube {
        background-image: url('../../../images/base/youtube.svg');
        background-size: auto 20px;
    }

    &-list {
        overflow: hidden;
    }

    &-wrap {
        margin-bottom: 19px;
    }
}
