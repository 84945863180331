.about {
    &-content {
        &-row {
            margin-bottom: 0;

            .block-about .text {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(sm) {
                .progress-list {
                    width: 314px;
                    float: right;
                }
            }
        }
    }

    &-experience {
        display: flex;
        flex-direction: column;
    }
}
