.section-about {
    @include media-breakpoint-up(sm) {
        padding-top: 25px;
        padding-bottom: 25px;

        &-padding {
            padding-top: 35px;
            padding-bottom: 10px;
        }

        .block-about {
            margin-bottom: 15px;
        }

        .text {
            line-height: 1.4;
        }
    }

    @include media-breakpoint-up(md) {
        padding-top: 35px;

        .section-content {
            margin-bottom: 50px;
        }

        &-padding {
            padding-bottom: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        .text {
            line-height: 1.5;
        }

        .btn {
            padding: 10px 35px;
        }

        &-padding {
            padding-top: 50px;
        }
    }
}
