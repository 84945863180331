.up {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    float: right;
    margin-top: -75px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    z-index: 1000;
    transition: opacity .15s ease-in-out;

    button {
        outline: none;
        position: relative;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        background-color: #c8c8d0;
        border: none;
        transition: background-color .15s ease-in-out;
        opacity: 1;
        visibility: visible;

        &:hover {
            background-color: #07509e;
        }

        &:after {
            position: absolute;
            top: -2px;
            left: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('../../../images/svg/arrow.svg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-size: 10px 15px;
            transform: rotate(270deg);
        }

        @include media-breakpoint-up(sm) {
            width: 55px;
            height: 55px;
            border-radius: 55px;

            &:after {
                background-size: 15px 20px;
            }
        }
    }
}
