.mobile-main {
    font-size: 1rem;
    font-weight: 400;

    li {
        padding-left: 35px;
        padding-right: 60px;
        background-color: #70b3cc;
        transition: background-color .3s linear;

        @include tablet {
            background-color: #70b3cc;
        }
    }

    .last a {
        border-bottom: 1px solid #bcdce7;
    }

    &__email {
        position: relative;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
        overflow: hidden;

        &::after {
            position: absolute;
            top: 0;
            content: '';
            display: block;
            width: 70%;
            height: 1px;
            background-color: #fff;
        }

        a {
            align-self: flex-start;
            text-transform: inherit !important;
        }
    }

    .bottom-mobile-contacts {
        a {
            padding-top: 7px;
            padding-bottom: 6px;
        }
    }

    >li.active,
    >li:hover {
        background-color: #8dc2d5;

        @include tablet {
            background-color: #8dc2d5;
        }
    }

    li.parent {
        overflow: hidden;
    }

    li.parent>a {
        position: relative;
    }

    li.parent>a:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 36px;
        display: block;
        width: 10px;
        height: 5px;
        margin-top: -3px;
        background-image: url('../../../images/svg/arrow-down-white.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    a {
        display: flex;
        align-items: center;
        padding: 15px 0;
        font-size: 16px;
        color: #ffffff;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
        }

        svg+span {
            margin-left: 20px;
        }
    }

    .submenu {
        display: none;

        &.open {
            display: block;
        }

        li a span {
            transition: border .3s linear;
            border-bottom: 1px solid transparent;
        }

        li.active a span,
        li:hover a span {
            border-bottom-color: #ffffff;
        }

        a {
            position: relative;
            padding-left: 70px;
            text-transform: none;
        }

        a:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 41px;
            width: 6px;
            height: 12px;
            margin-top: -6px;
            background-image: url('../../../images/svg/arrow-right-white.svg');
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}
