.office {
    &__block {
        display: grid;
        grid-template-columns: repeat(2, auto);
        align-items: center;
        gap: 30px;
        padding-top: 30px;

        &:nth-of-type(1) {
            padding-top: 0;
        }

        @include phone {
            display: flex;
            flex-direction: column;
        }

        @include tablet {
            display: flex;
            flex-direction: column;
        }

        h3 {
            margin-bottom: 14px;
            font-size: 24px;
            line-height: 24px;
        }

        .office__block--picture {
            display: flex;
            justify-content: center;

            img {
                width: 464px;
                max-width: 464px;
                height: 364px;
                max-height: 306px;

                @include phone {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &:nth-of-type(1) {
            .office__block--picture {
                @include desktop {
                    margin-right: 60px;
                    width: 450px;
                }
            }

            ul {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-self: flex-start;
                margin: 48px 0;
                margin-bottom: 20px;
                gap: 30px;
                grid-column-start: 1;
                grid-column-end: 3;

                @include phone {
                    display: flex;
                    flex-direction: column;
                    margin-left: 1em;
                }

                li {
                    margin-left: 1rem;
                    min-width: 400px;
                    max-width: 400px;
                    font-size: 16px;

                    @include phone {
                        margin-left: 0;
                        min-width: 0;
                    }

                    @include desktop {
                        min-width: 372px;
                        max-width: 372px;
                    }

                    &::before {
                        content: '\2022';
                        display: inline-block;
                        color: #165aa6;
                        font-weight: bold;
                        width: 1em;
                        min-width: 1em;
                        height: 1em;
                        min-height: 1em;
                        margin-left: -1em;
                    }
                }
            }
        }

        &.even {
            align-items: center;
            grid-template-rows: 1fr;
            margin-bottom: 0px;

            @include desktop {
                gap: 108px;
            }

            @include phone {
                margin-bottom: 40px;
            }

            .office__block--picture {
                @include desktop {
                    margin-left: 60px;
                }

                grid-column: 1;
                grid-row: 1;
            }

            .office__block--info {
                grid-column: 2;
            }
        }

        &:nth-of-type(3) {
            align-items: center;
        }

        &--info p {
            font-size: 16px !important;
            line-height: 24px !important;
        }
    }
}
