.slider-main {
    position: relative;
    width: 100%;

    .slick-slide {
        height: 100%;
    }

    &.slick-initialized {
        .slide-main {
            position: relative;
        }
    }
}
