h1,
.h1 {
    font-size: 58px;
    color: #293650;

    @include tablet {
        font-size: 86px;
    }

    @include desktop {
        font-size: 97px;
    }
}

h2,
.h2 {
    font-size: 39px;
    font-weight: 700;
    text-transform: uppercase;

    @include phone {
        font-size: 26px;
    }

    @include tablet {
        font-size: 32px !important;
    }

    @include desktop {
        font-size: 36px;
    }
}

h3,
.h3 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 20px;

    @include tablet {
        font-size: 39px;
    }

    @include desktop {
        font-size: 47px;
    }
}

h1, h2, h3,
.h1, .h2, .h3 {
    font-family: "Open Sans SemiBold", sans-serif;
}

.section-info {
    font-size: 16px;
    line-height: 24px;
}

p {
    font-size: 27px;
    line-height: 1.6;
}

a {
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s linear,
    text-decoration 0.2s linear;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: 0;
    }

    &.link-blue {
        color: #4b93ad;
        @include underline-blue;

        &:hover {
            border-bottom-color: transparent;
            text-decoration: none;
        }

        &.on_u {
            border-bottom-color: transparent;
            text-decoration: none;

            &:hover {
                @include underline-blue;
            }
        }
    }

    &.link-white {
        color: $white;
        @include underline-white;
        transition: border-bottom 0.2s linear;

        &:hover {
            border-bottom-color: transparent;
            text-decoration: none;
        }
    }

    &.link-gray {
        color: $gray;
        @include underline-gray;
        transition: color 0.2s linear,
            border-bottom 0.2s linear;

        &:hover {
            border-bottom: 1px solid transparent;
            text-decoration: none;
        }
    }

    &.link-dark {
        color: $primary;
        @include underline-dark;
        transition: color 0.2s linear,
            border-bottom 0.2s linear;

        &:hover {
            border-bottom: 1px solid transparent;
            text-decoration: none;
        }

        &.on_u {
            border-bottom-color: transparent;
            text-decoration: none;

            &:hover {
                @include underline-dark;
            }
        }
    }

    &.no_u {
        text-decoration: none;
        border-bottom: 1px solid transparent;

        &:hover {
            text-decoration: none;
            border-bottom: 1px solid transparent;
        }
    }
}

.section-description a,
.section-wysiwyg a {
    color: $blue;
    @include underline-blue;

    &:hover {
        border-bottom-color: transparent;
        text-decoration: none;
    }
}

.gray {
    color: $gray;
}

strong,
.strong {
    font-weight: 700;
}

.semibold {
    font-weight: 600;
}

.light {
    font-weight: 300;
}

.list,
.project-item ul {
    line-height: 1.3;
    list-style-type: none;
    margin-bottom: 15px;

    li {
        position: relative;
        font-size: 0.875rem;
        // letter-spacing: -0.25px;
        padding-left: 20px;
        margin-bottom: 3px;

        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            @include gradient;
        }
    }
}


.animate-width {
    transition: width .3s ease-in-out;
}

// @include media-breakpoint-up(sm) {

//     h1,
//     .h1 {
//         font-size: 2.75rem;
//     }

//     h2,
//     .h2 {
//         font-size: 1.625rem;
//     }

//     h3,
//     .h3 {
//         font-size: 1.25rem;
//     }
// }

@include media-breakpoint-up(md) {

    // h2,
    // .h2 {
    //     font-size: 2.125rem;
    // }

    .list,
    .project-item ul {
        margin-bottom: 20px;
        line-height: 1.5;

        li {
            font-size: 1rem;
            padding-left: 35px;
            margin-bottom: 0;
        }
    }
}

// @include media-breakpoint-up(lg) {

//     h1,
//     .h1 {
//         font-size: 3.125rem;
//     }

//     h2,
//     .h2 {
//         font-size: 2.125rem;
//     }

//     h3,
//     .h3 {
//         font-size: 1.5rem;
//     }
// }
