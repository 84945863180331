.footer {
    position: relative;
    flex-grow: 0;
    font-size: 0.875rem;
    color: #ffffff;
    background-color: $primary;
    line-height: 1.3;

    @include media-breakpoint-up(sm) {
        font-size: 0.9375rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1rem;

        &:after {
            bottom: 70px;
        }
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.125rem;

        .copy-wrap {
            font-size: 0.875rem;
        }
    }

    &-inner {
        padding-bottom: 19px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
        }
    }

    &-row {
        padding-top: 23px;
        padding-bottom: 15px;

        @include media-breakpoint-up(sm) {
            padding-top: 35px;
            padding-bottom: 20px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 35px;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 4px;
        }
    }

    &-header {
        margin-bottom: 10px;
        font-size: 0.875rem;
        text-transform: uppercase;
        color: #b4becb;

        @include media-breakpoint-up(sm) {
            margin-bottom: 23px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
            font-size: 1rem;
        }
    }

    &-list {
        margin: 0 0 15px 0;
        padding: 0;
        list-style-type: none;

        @include media-breakpoint-up(sm) {
            margin-bottom: 12px;
        }

        li {
            font-size: 0.875rem;
            line-height: 1.5;
            padding-top: 6px;
            padding-bottom: 6px;

            @include media-breakpoint-up(sm) {
                padding-bottom: 6px;
            }

            @include media-breakpoint-up(lg) {
                font-size: 1rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 1rem;
            }
        }

        a {
            color: #ffffff;
            border-bottom: 1px solid rgba(255, 255, 255, 0.4);
            transition: border-bottom-color 0.2s linear;

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }

    &-delimiter {
        width: 100%;
        height: 3px;
        background-color: #7f7f8a;

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    @include media-breakpoint-up(sm) {
        &-second {
            height: 93px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            font-size: 0.875rem;
        }
    }

    @include media-breakpoint-up(lg) {
        &-second {
            height: 70px;
        }
    }

    &-phone {
        line-height: 1.5;
    }

    address {
        margin-top: 17px;
    }
}
