.slide-inner {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px 0;

    @include phone {
        padding: 15px 0 0;
    }

    @include media-breakpoint-up(sm) {
        flex-direction: row;
        padding: 45px 0 0;
    }

    @include desktop {
        .str-small {
            font-size: 30px !important;
        }
    }

    @include tablet {
        .str-small {
            font-size: 30px !important;
        }
    }

    @include media-breakpoint-up(md) {
        .slide-left {
            .line-break {
                display: inline-block;
            }
        }

        .slide-right .info-slide {
            font-size: 2.5rem;
            font-weight: 400;
        }
    }

    @include media-breakpoint-up(lg) {
        .slide-left {
            max-width: 62%;

            .title-slide {
                flex: 0 0 630px;

                .str-small {
                    padding-right: 200px;
                }
            }
        }
    }

    @include desktop {
        height: 500px !important;
    }

    .slide-left {
        display: flex;
        overflow: hidden;
        max-width: 80%;

        @include media-breakpoint-up(sm) {
            padding: 0;

            .line-break {
                display: block;
            }
        }

        @include phone {
            min-height: 200px;
            max-width: 100%;
        }

        @include desktop {
            height: 500px !important;
        }
    }

    .slide-right {
        height: 75px;
        text-align: center;
        padding: 20px 0;
        z-index: 10;

        .btn {
            width: 100%;
            max-width: 320px;
        }

        .animated.fadeInUp {
            animation-duration: 1.5s;
            animation-delay: 0.3s;
        }

        @include media-breakpoint-up(sm) {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0;
            padding-right: 45px;
            text-align: right;

            .info-slide {
                font-weight: 600;
                font-size: 2.125rem;
                line-height: 1.3;
                color: $white;
                text-align: right;
                margin-bottom: 25px;
            }

            .btn {
                width: auto;
            }
        }
    }
}
