.select2 {
    width: 100% !important;

    @include media-breakpoint-up(sm) {
        max-width: 420px;

        &.select2-container.select2-container--default {
            max-width: 420px !important;
            width: 100% !important;
        }
    }

    .select2-selection {
        height: 50px;
        border-radius: 47px;
        border: 2px solid #b1bed4;
        border-bottom-left-radius: 47px !important;
        border-bottom-right-radius: 47px !important;
    }

    &-container {
        background-color: #fff;

        .select2-selection--single {
            height: 35px;

            .select2-selection__rendered {
                padding-left: 15px;
                padding-right: 25px;
            }
        }

        &--default {
            &.select2-container--below {
                z-index: 1;
            }

            .select2-selection--single {
                border: 1px solid #cdd5e3;
                outline: none;

                .select2-selection__rendered {
                    line-height: 32px;
                    font-size: 0.875rem;
                }

                .select2-selection__placeholder {
                    font-size: 0.875rem;
                    color: #b1bed4;
                }

                .select2-results__option--selected {
                    background-color: #cdd6e9;
                }

                .select2-selection__arrow {
                    width: 33px;
                    height: 33px;

                    b {
                        width: 10px;
                        height: 10px;
                        border: none;
                        background-image: url('../../../images/vacancy/arrow-down-gray.svg');
                        background-repeat: no-repeat;
                        background-position: 92% center;
                        background-size: 10px;
                        margin-top: -5px;
                    }
                }
            }

            .select2-results__option--highlighted.select2-results__option--selectable {
                background-color: #fff;
                color: #424852;

                &:hover {
                    background-color: #ebebeb;
                }

                &:active,
                &:focus {
                    background-color: #d3e7ef;
                }
            }

            .select2-selection--single .select2-selection__rendered {
                color: $primary;
            }

            @include media-breakpoint-up(md) {
                .select2-selection--single {

                    .select2-selection__placeholder,
                    .select2-selection__rendered {
                        font-size: 1rem;
                    }
                }
            }
        }
    }

    &-dropdown {
        top: -18px;
        padding-top: 18px;
        box-shadow: 0px 4px 10px 0px rgba(13, 13, 13, 0.15);
        border: none;
        border-radius: 0;
        z-index: 0;
    }

    &-results__option {
        font-size: 1rem;
        padding: 8px 15px;
    }
}

.country-select {
    .select2 {
        &.select2-container.select2-container--default {
            max-width: none !important;
        }
    }

    .select2-container {
        .select2-selection--single {
            height: 48px;
        }

        &--default {
            .select2-selection--single {
                .select2-selection__rendered {
                    line-height: 45px;
                    font-size: 0.875rem;
                }

                .select2-selection__arrow {
                    width: 45px;
                    height: 45px;
                }
            }

            .select2-selection--single .select2-selection__placeholder,
            .select2-results__option {
                font-size: 0.875rem;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .select2-container--default {
            .select2-selection--single {
                .select2-selection__rendered,
                .select2-selection__placeholder {
                    font-size: 1rem;
                }
            }
        }

        .select2-results__option {
            font-size: 1rem;
        }
    }
}
