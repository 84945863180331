.principle-operation {

    @include desktop {
        margin-bottom: 50px !important;
    }

    .h2 {
        margin-bottom: 34px !important;
        text-align: left;

        @include phone {
            margin-top: 34px;
        }
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
        row-gap: 8px;

        @include phone {
            display: flex;
            flex-direction: column;
        }

        @include tablet {
            display: flex;
            flex-direction: column;
        }
    }

    &__block {
        &:nth-of-type(3) {
            p:first-of-type {
                margin-bottom: 8px;
            }
        }

        img {
            margin-bottom: 18px;
            width: 85px;
            height: 85px;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }

        h3 {
            font-size: 24px;
            line-height: 24px;

            @include desktop {
                margin-bottom: 12px;
            }
        }
    }
}
