.connection {
    display: flex;
    flex-direction: column;
    margin: 0 0 20px;
    padding: 0;

    &__item {
        margin-bottom: 5px;
        span {
            display: block;
            color: #afafaf;
            font-size: 0.875rem;
        }
    }

    &__link {
        position: relative;
        margin-left: 40px;
        color: $primary;
        font-size: 1rem;

        &:before {
            content: '';
            width: 20px;
            height: 23px;
            position: absolute;
            top: 0;
            left: -40px;
            background-repeat: no-repeat;
            background-position:center center;
            background-size: auto 18px;
        }

        &--address:before {
            background-image: url('../../../images/base_content/svg/002-placeholder.svg');
        }

        &--smartphone:before {
            background-image: url('../../../images/base_content/svg/smartphone.svg');
        }

        &--phone:before {
            background-image: url('../../../images/base_content/svg/001-phone-call.svg');
        }

        &--email:before {
            background-image: url('../../../images/base_content/svg/004-email.svg');
        }

        &--site:before {
            background-image: url('../../../images/base_content/svg/globalization.svg');
        }

        &--skype:before {
            background-image: url('../../../images/base_content/svg/skype-logo.svg');
        }
    }

    p.connection__link {
        margin-bottom: 10px;
    }
}
