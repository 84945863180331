.products-list {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;

    @include tablet {
        flex-direction: row;
    }

    @include desktop {
        flex-direction: row;
    }

    &-client {
        .product {
            padding-top: 0;
            padding-bottom: 15px;
            border-bottom: none;

            &-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &-icon {
                width: 60px;
                height: 60px;
                margin-top: 0;
                &.product-icon-in-title {
                    display: none;
                }
            }

            &-name {
                margin-bottom: 5px;
            }

            &:hover {
                color: $primary;
                &:after {
                    opacity: 0;
                }
            }
        }
    }
}
