.progress {
    &-list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;

        &.progress-list-two-elems {
            .progress-item {
                justify-content: flex-start;
                border-bottom: none;
            }
        }

        &-mb {
            margin-bottom: 15px;
        }

        @include media-breakpoint-up(sm) {
            &-two-elems {
                .progress-item {
                    flex-basis: 50%;
                    max-width: 50%;
                    &:nth-child(2) {
                        border-right: none;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 45px;

            &-two-elems {
                .progress-item {
                   padding-top: 25px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            min-width: 350px;
            height: 330px;
            flex: 0 0 30%;
            margin-left: 30px;
            justify-content: center;

            &-two-elems {
                min-width: 380px;
                margin-left: 0;

                .progress-item {
                    height: auto;
                }
            }
        }
    }

    &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 15px 5px 10px;

        &:nth-child(2n+1) {
            border-right: 1px solid #e8e8ec;
        }

        &:nth-child(-n+2) {
            border-bottom: 1px solid #e8e8ec;
        }

        @include media-breakpoint-up(sm) {
            justify-content: flex-start;
            flex: 0 0 25%;
            min-height: 122px;
            padding-left: 8px;
            padding-right: 8px;

            &:nth-child(1) {
                order: 1;
            }

            &:nth-child(2) {
                order: 3;
            }

            &:nth-child(3) {
                order: 2;
            }

            &:nth-child(4) {
                order: 4;
            }

            &:nth-child(2n+1) {
                border-right: none;
            }

            &:nth-child(-n+2) {
                border-bottom: none;
            }

            &:nth-child(-n+3) {
                border-right: 2px solid #e8e8ec;
            }
        }

        @include media-breakpoint-up(md) {
            min-height: 140px;
            max-height: 100%;
        }

        @include media-breakpoint-up(lg) {
            min-width: 175px;
            height: 165px;
            flex: 0 0 50%;
            max-width: 50%;
            border-right: none;
            padding: 25px 15px 12px;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                order: 0;
            }

            &:nth-child(-n+3) {
                border-right: none;
            }

            &:nth-child(odd) {
                border-right: 1px solid #e8e8ec;
            }

            &:nth-child(2) {
                border-right: none;
            }

            &:nth-child(-n+2) {
                border-bottom: 1px solid #e8e8ec;
            }
        }
    }

    &-title {
        font-size: 2.875rem;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 0.8;
        color: $blue;
        margin-bottom: 10px;

        &-sub {
            font-size: 1.625rem;
        }

        @include media-breakpoint-up(sm) {
            font-size: 3.5rem;
            margin-bottom: 15px;

            &-sub {
                font-size: 2.25rem;
            }
        }

        @include media-breakpoint-up(md) {
            font-size: 4.375rem;

            &-sub {
                font-size: 2.5rem;
            }
        }
    }

    &-text {
        font-size: 0.875rem;
        line-height: 1.2;

        @include media-breakpoint-up(sm) {
            font-size: 1rem;
        }

        @include media-breakpoint-up(md) {
            font-size: 1.125rem;
        }
    }
}
