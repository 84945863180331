.benefits {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 30px;

    @include phone {
        display: flex;
        flex-direction: column;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
    }

    &-list {
        display: flex;
        flex-direction: column;
        gap: 26px;

        @include phone {
            gap: 45px;
        }

        &__el {
            display: grid;
            grid-template-columns: 83px 100%;
            max-width: 480px;
            gap: 25px;
            align-items: flex-start;

            @media (min-width: 1024px) and (max-width: 1200px) {
                max-width: 400px;
            }

            @media (min-width: 960px) and (max-width: 1023px) {
                max-width: 360px;
            }

            @include phone {
                display: flex;
                flex-direction: column;
                gap: 0;
            }

            img {
                grid-column: 1;
                grid-row: 1;
            }

            span {
                display: flex;
                flex-direction: column;
                margin-top: 14px;
                gap: 6px;
                grid-column: 2;
                grid-row: 1;

                p {
                    margin-bottom: 0;
                    font-size: 18px;
                    font-weight: 700;
                }

                ul {
                    display: flex;
                    flex-direction: column;
                    margin-left: 1em;
                    padding-right: 15px;
                    gap: 2px;
                    width: 100%;

                    li {
                        font-size: 16px;

                        @include phone {
                            font-size: 14px;
                        }

                        &::before {
                            content: '\2022';
                            display: inline-block;
                            color: #165aa6;
                            font-weight: bold;
                            width: 2em;
                            min-width: 2em;
                            height: 1em;
                            min-height: 1em;
                            margin-left: -1em;
                            padding-right: 1em;

                            @include phone {
                                width: 1em;
                                min-width: 1em;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }

        &__picture {
            width: 83px;
            height: 83px;
        }
    }

}
