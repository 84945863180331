.section-convenience {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @include phone {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    @include tablet {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    h3 {
        grid-column-start: 1;
        grid-column-end: 4;
        text-transform: uppercase;
        font-size: 34px;

        @include phone {
            margin-top: 20px;
            font-size: 26px;
        }

        @include tablet {
            font-size: 32px;
        }
    }

    img {
        width: 83px;
        height: 83px;
    }

    &__block {
        display: flex;
        flex-direction: column;
        gap: 24px;

        p {
            font-size: 16px;
            line-height: 24px;
        }

        &--long {
            gap: 27px;

            @include phone {
                gap: 10px;
            }

            @include tablet {
                gap: 10px;
            }

            img {
                width: min-content;
                max-width: 230px;
                height: 60px;
                max-height: 60px;
            }

            &:nth-last-of-type(2) {
                img {
                    position: relative;
                    top: 2px;
                    right: 12px;
                    margin-bottom: 10px;
                    height: 50px;
                }
            }
        }
    }
}
