.section-product-advantage {
    @include desktop {
        margin-bottom: 10px;
    }

    .section-content {
        padding-top: 15px;
        margin-bottom: 0;

        + .section-action {
            margin-top: 30px;
        }
    }

    &-indent {
        padding-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
        padding-bottom: 10px;

        &-indent {
            padding-top: 0;
        }
    }

    @include media-breakpoint-up(md) {
        padding-bottom: 25px;

        .section-content + .section-action {
            margin-top: 45px;
        }

        .section-title {
            margin-bottom: 30px;
        }
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: 40px;

        &-indent {
            padding-bottom: 45px;
        }
    }

    @include desktop {
        .section-content {
            padding-top: 0;
        }
    }
}
