.info-slide {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    color: #fff;

    @include phone {
        margin-top: 22px;
    }
}
