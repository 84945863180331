$font-family-sans-serif: "Open Sans", sans-serif;

// Grid

$grid-breakpoints: (
        xs: 0,
        sm: 724px,
        md: 960px,
        lg: 1140px,
        xl: 1354px
) !default;
$container-max-widths: (
        xl: 1354px
) !default;

// Base
$border-radius: 27px;
$border-width: 2px;

$btn-border-radius:           $border-radius;
$btn-border-radius-lg:        $border-radius;
$btn-border-radius-sm:        $border-radius;

$link-color:                              $link !default;
$link-hover-color:                        lighter($link-color, 30%) !default;

// Buttons
$btn-font-size: .875rem;
$input-btn-border-width:         1px;

// Forms
$custom-select-box-shadow:          none !default;
$custom-select-focus-box-shadow:    none !default;
$custom-select-bg-size:             10px 10px !default;

$input-font-size:                   14px !default;
$input-font-size-lg:                16px !default;
$input-color:                       #424952 !default;
$input-border-color:                #cbd4e2 !default;
$input-font-weight:                 300 !default;
$input-border-radius:               3px !default;
$input-border-radius-lg:            3px !default;
$input-border-radius-sm:            3px !default;
$input-focus-border-color:          #4d93ad !default;
$input-focus-box-shadow:            none !default;

$input-focus-box-shadow: none;
$btn-focus-box-shadow: none;
$btn-font-weight: 700;

// Checkbox
$custom-checkbox-indicator-border-radius:       2px !default;
$custom-control-indicator-size:                 1.45rem !default;
$custom-control-indicator-checked-border-color: #4d93ad !default;
$custom-control-indicator-border-color:         #b6c2d7!default;
$custom-control-indicator-focus-border-color:   $custom-control-indicator-border-color !default;
$custom-control-indicator-checked-bg:           #ffffff !default;
$custom-control-indicator-checked-color:        #4d93ad !default;
$custom-control-indicator-active-bg:            #ffffff !default;
$custom-control-indicator-active-border-color:  #4d93ad !default;

// Modal
$modal-md: 618px !default;
$modal-content-border-radius: 3px !default;
$modal-header-border-width: 0 !default;
$modal-header-padding: 29px 40px 14px !default;
$modal-inner-padding: 16px 40px !default;
