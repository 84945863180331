.footer-banner {
    position: relative;
    display: flex;
    justify-content: flex-end;
    min-height: 204px;

    @include phone {
        margin-top: 35px;
        background: linear-gradient(90deg, rgba(33,69,137,1) 0%, rgba(36,158,207,1) 50%, rgba(33,69,137,1) 100%);
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        z-index: 0;
        object-fit: cover;

        @include phone {
            display: none;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-self: center;
        flex-basis: 60%;
        gap: 40px;
        z-index: 1;

        @include phone {
            gap: 20px;
            padding: 0 15px;
            flex-basis: 100%;
        }
    }

    &__title,
    &__text {
        color: #fff;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
    }

    &__title {
        margin-top: 16px;
        margin-bottom: 0;
        font-size: 34px;
        font-weight: 600;
    }

    &__text {
        font-size: 24px;
        line-height: 24px;
        font-weight: 400;
    }
}
