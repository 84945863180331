.mt {
    display: flex;
    flex-direction: row;

    &-image {
        display: flex;
        align-items: center;
        margin-right: 9px;

        img {
            width: 30px;
            height: 30px;
        }
    }

    &-text {
        line-height: 1;
        font-size: 0.75rem;

        span {
            padding-bottom: 4px;
        }

        a {
            letter-spacing: -.2px;
        }
    }

    &-wrap {
        margin-top: 19px;

        @include media-breakpoint-up(sm) {
            width: 25%;
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            padding-right: 0;

            .mt-text {
                font-size: 0.875rem;
                letter-spacing: 0.5px;
            }
        }

        @include media-breakpoint-up(lg) {
            .mt-text {
                font-size: 0.9375rem;
            }
        }
    }
}
