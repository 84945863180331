.results {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    @include media-breakpoint-up(md)  {
        margin-bottom: 0;
    }

    &__head {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        @include media-breakpoint-up(md)  {
            min-height: 96px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 115px;
        }

        @include media-breakpoint-up(xl)  {
            min-height: auto;
            margin-bottom: 30px;
        }
    }

    &__icon {
        display: block;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center top;
        margin-right: 20px;

        &--speed {
            background-image: url('/images/inet-bank/01-speed.svg');
        }

        &--income {
            background-image: url('/images/inet-bank/02-income.svg');
        }

        &--reputation {
            background-image: url('/images/inet-bank/03-reputation.svg');
        }

        &--process {
            background-image: url('/images/svg/treasury/001-process.svg');
        }

        &--growth {
            background-image: url('/images/svg/treasury/002-growth.svg');
        }

        &--comfort {
            background-image: url('/images/svg/treasury/003-armchair.svg');
        }

        &--ecosystem {
            background-image: url('/images/svg/post/001-icon-2942621.svg');
        }

        &--scale {
            background-image: url('/images/svg/post/002-scale.svg');
        }

        &--future {
            background-image: url('/images/svg/post/003-city.svg');
        }

        &--full {
            background-image: url('/images/svg/abs/002-management.svg');
        }

        &--optimization {
            background-image: url('/images/svg/abs/003-analysis.svg');
        }

        &--extension {
            background-image: url('/images/svg/abs/004-website.svg');
        }

        @include media-breakpoint-up(sm)  {
            width: 70px;
            height: 70px;
        }

        @include media-breakpoint-up(md)  {
            width: 75px;
            height: 75px;
            margin-right: 25px;
        }
    }

    &__title {
        margin-bottom: 0;
    }

    &-list {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md)  {
            flex-direction: row;

            &__item {
                flex: 31%;
                max-width: 31%;

                &+& {
                    margin-left: 20px;
                }
            }
        }
    }
}
