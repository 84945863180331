.search {
    float: right;
    position: relative;
    width: 42px;
    height: 42px;

    &-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        padding-left: 13px;

        @include media-breakpoint-up(sm) {
            margin-left: auto;
        }

        &__phone {
            @include phone {
                display: none;
            }

            font-size: 16px;
            font-family: 'Open Sans SemiBold', sans-serif;
            font-weight: 700;
            color: #293650;


            &.header-phone {
                color: #000;
                font-weight: normal;
                &:hover {
                    border-bottom: 1px solid transparent;
                }
            }
        }

        a {
            display: none;

            @include desktop {
                display: block;
                margin-right: 34px;
                font-size: 16px;
                font-weight: 600;
                font-family: 'Open Sans', sans-serif;
                color: #4d93ad;
                border-bottom: 1px solid transparent;

                &:hover {
                    border-bottom: 1px solid #4d93ad;
                }
            }
        }
    }

    &.closed {
        overflow: hidden;
    }

    .find {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 42px;
        height: 42px;
        padding: 0;
        border: none;
        border-radius: 50%;
        background: linear-gradient(180deg, #4773b5 0%, #7fbfd7 100%);
        outline: none;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('../../../images/svg/loupe.svg');
            background-repeat: no-repeat;
            background-size: 17px;
            background-position: center;
            transform: rotate(0deg) scale(1);
            opacity: 1;
            visibility: visible;
            transition: .3s transform;
        }

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('../../../images/svg/close.svg');
            background-repeat: no-repeat;
            background-size: 15px;
            background-position: center;
            border-radius: 50%;
            opacity: 0;
            visibility: hidden;
            transform: rotate(-90deg) scale(.4);
            transition: .3s transform;
        }
    }

    input {
        position: absolute;
        right: 0;
        z-index: 1;
        width: 42px;
        height: 100%;
        padding-left: 18px;
        border: 1px solid #70b3cc;
        border-radius: 42px;
        outline: none !important;

        &:focus {
            outline: none !important;
        }

        &::placeholder {
            font-size: 0.875rem;
            color: #3d4752;
            opacity: 1;
        }
    }

    .loupe {
        position: absolute;
        top: 0;
        right: 42px;
        z-index: 2;
        width: 42px;
        height: 42px;
        background-image: url('../../../images/svg/loupe-blue.svg');
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        cursor: pointer;
        animation-duration: 300ms;
    }

    &.opened {
        .find {
            &:before {
                opacity: 0;
                visibility: hidden;
                transform: rotate(90deg) scale(.4);
            }

            &:after {
                transform: rotate(0deg) scale(1);
                opacity: 1;
                visibility: visible;
            }
        }

        .loupe {
            z-index: 5;
            opacity: 1;
        }
    }

    &-result {
        &__content {
            margin-bottom: 0;
        }
    }
}
