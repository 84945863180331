.mobile-nav {
    position: absolute;
    width: 100%;
    min-height: 1vh;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    overflow: auto;
    background-color: #70b3cc;

    &-wrap {
        width: 100%;
        display: none;
        overflow: hidden;
        position: fixed;
        top: 88px;
        bottom: 0;
        left: 0;
        z-index: 1050;
        -webkit-overflow-scrolling: touch;
        outline: 0;

        @include media-breakpoint-up(sm) {
            top: 95px;
            width: 360px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }
}
