.clients-info {
    @include desktop {
        margin-bottom: 62px !important;
    }

    &__container {
        @include desktop {
            display: flex;
            flex-direction: column;
            gap: 36px;
        }
    }

    &__block {
        align-items: center;

        @include tablet {
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 16px;
        }

        @include desktop {
            display: grid;
            grid-template-columns: 50% 50%;
            gap: 30px;
        }

        &--reverse {
            @include phone {
                margin-top: 16px;
            }

            @include tablet {
                display: grid;
                grid-template-columns: 50% 50%;
                grid-auto-flow: dense;
                gap: 16px;
            }

            @include desktop {
                display: grid;
                grid-template-columns: 50% 50%;
                grid-auto-flow: dense;
                gap: 20px;

                .clients-info__header {
                    margin-top: 60px;
                }
            }

            .clients-info__picture {
                grid-column: 1;
            }

            .clients-info__header {
                grid-column: 2;
                margin-top: 95px;
            }

            .clients-info__title {
                @include desktop {
                    margin-bottom: 34px;
                }
            }

            .clients-info__images {
                margin-top: 16px;
                margin-bottom: 0;
                object-fit: cover;

                @include tablet {
                    align-self: center;
                    max-width: 335px;
                    max-height: 224px;
                }
            }
        }
    }

    &__header {
        h2 {
            margin-bottom: 34px;
            font-size: 32px;
            line-height: 34px;

            @include phone {
                font-size: 26px;
            }
        }

        p {
            margin-bottom: 8px;
            font-size: 16px;
            line-height: 24px;

            @include tablet {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &__subtitle {
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 24px;
    }

    &__picture {
        position: relative;
        display: flex;
        justify-content: center;

        img {
            margin-bottom: 16px;
            object-fit: cover;

            @include tablet {
                align-self: center;
                max-width: 335px;
                max-height: 224px;
            }

            max-width: 464px;
            max-height: 310px;
        }
    }
}
