@mixin phone {
    @media (min-width: 320px) and (max-width: 719px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 720px) and (max-width: 959px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 960px) {
        @content;
    }
}
