.logo-wrapper {
    max-width: 264px;
    padding: 0;

    @include media-breakpoint-up(xl) {
        margin-left: 0;
    }

    @include phone {
        img {
            min-width: 200px;
            max-width: 240px;
        }
    }
}
