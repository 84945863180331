.filtered-cards {
    margin: 20px 0;

    @include media-breakpoint-up(md) {
        margin: 35px 0 30px;
    }

    @include media-breakpoint-up(xl) {
        margin: 50px 0 35px;
    }
}
