.breadcrumbs {
    display: none;
    margin-bottom: 4px;

    @include media-breakpoint-up(sm) {
        display: block;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 7px;

        .breadcrumb li {
            display: inline;
            font-size: 1rem;

            .delimiter {
                font-size: 1rem;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 9px;
    }

    .breadcrumb {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            font-size: 0.875rem;

            @include media-breakpoint-up(md) {
                font-size: 1rem;
            }

            a {
                color: #fff;
                font-weight: 300;
                text-decoration: none;
                border-bottom: 1px solid rgba(#fff, .3);
                padding: 0;
                margin: 0 3px;

                &:hover {
                    border-bottom-color: transparent;
                    text-decoration: none;
                }
            }

            .delimiter {
                color: #fff;
                font-weight: 300;

                @include media-breakpoint-up(md) {
                    font-size: 1rem;
                }
            }
        }

        li.active {
            color: #fff;
            text-decoration: none;
        }
    }
}
