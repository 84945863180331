.title-slide {
    padding-right: 15px;
    opacity: 0;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 300;
    // flex: 0 0 225px;
    color: #fff;

    span {
        display: block;
        margin-bottom: 26px;

        @include phone {
            margin-bottom: 10px;
        }
    }

    @include phone {
        flex: auto;
    }

    .str-small {
        padding-right: 0 !important;

        @include phone {
            font-size: 1.25rem;
        }

        @include tablet {
            font-size: 2.25rem;
        }

        @include desktop {
            font-size: 3.125rem;
        }
    }


    @include phone {
        .big {
            font-size: 1.875rem !important;
        }
    }

    @include tablet {
        flex: 0 0 560px;
        .big {
            font-size: 2.75rem !important;
            // line-height: 54px;
        }
    }

    @include desktop {
        flex: 0 0 630px;

        .big {
            font-size: 3.4375rem !important;
            // line-height: 54px;
        }
    }

    &.active {
        opacity: 1;
    }

    &.animated {
        animation-duration: 1.5s;
        animation-delay: 0.3s;

        &.active {
            animation-delay: 0s;
        }
    }

    .big {
        display: inline-block;
        font-size: 1.875rem;
    }
}
