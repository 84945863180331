.employee-card {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 400px;
        padding: 0 45px 30px;
    }

    &__menu {
        margin-right: 20px;
    }

    &__image {
        width: 300px;
        height: 360px;
        background-color: #ececec;
        margin-bottom: 25px;

        img {
            object-fit: cover;
            object-position: center center;
            height: 100%;
        }

        &--no-photo {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px;

            img {
                object-fit: contain;
            }
        }
    }
    &__info {
        text-align: center;
        margin-bottom: 17px;
    }
    &__name {
        margin-bottom: 8px;
    }
    &__post {
        font-size: 1.125rem;
        line-height: 1.2;
    }
    &__connection-block {
        margin-bottom: 20px;
    }
    &__action {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 20px;
        margin-bottom: 15px;
        .btn {
            color: #fff;
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 15px;
            padding: 10px 25px;

            .fa {
                font-size: 1.125rem;
            }
        }
    }

    &__subtitle {
        font-size: 1rem;
        margin-bottom: 10px;
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border-top: 2px solid #ededed;
        text-align: center;
        padding: 15px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 65px;
            height: 65px;
            border-radius: 5px;
            background-color: #dcdcdc;
            color: $primary;

            i {
                font-size: 32px;
            }
        }
    }
}
